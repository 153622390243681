<template>
  <!--
    Used to edit the Resorts 
  -->
  <div class="resortDetails">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="resort">
        <h4>{{ $t('resortForm.basicInfo') }}</h4>
        <div class="ml-4 mt-4">
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('id') }}</label>
            <div class="col-12 col-md-3">
              <span>{{ resort.id }}</span>
              <span
                v-show="errors.has('Id')"
                class="badge badge-danger"
              >{{ errors.first('Id') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('name') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.name"
                v-focus
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Name') }]"
                type="text"
              >
              <span
                v-show="errors.has('Name')"
                class="badge badge-danger"
              >{{ errors.first('Name') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.link') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.link"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Link') }]"
                type="text"
              >
              <span
                v-show="errors.has('Link')"
                class="badge badge-danger"
              >{{ errors.first('Link') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.companyName') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.companyName"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('CompanyName') }]"
                type="text"
              >
              <span
                v-show="errors.has('CompanyName')"
                class="badge badge-danger"
              >{{ errors.first('CompanyName') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <i>Description affects the serviceProvider in Contentful, if found</i>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.descriptionGerman') }}</label>
            <div class="col-12 col-md-3">
              <textarea
                v-model="resort.description.de"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('DescriptionDe') }]"
                type="text"
              />
              <v-popover
                v-if="resort.description.de"
                offset="16"
              >
                <font-awesome-icon
                  class="ml-2 tooltip-target"
                  icon="external-link-square-alt"
                />
                <template slot="popover">
                  <span
                    v-html="resort.description.de"
                  />
                </template>
              </v-popover>
              <span
                v-show="errors.has('DescriptionDe')"
                class="badge badge-danger"
              >{{ errors.first('DescriptionDe') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.descriptionEnglish') }}</label>
            <div class="col-12 col-md-3">
              <textarea
                v-model="resort.description.en"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('DescriptionEn') }]"
                type="text"
              />
              <v-popover
                v-if="resort.description.en"
                offset="16"
              >
                <font-awesome-icon
                  class="ml-2 tooltip-target"
                  icon="external-link-square-alt"
                />
                <template slot="popover">
                  <span
                    v-html="resort.description.en"
                  />
                </template>
              </v-popover>
              <span
                v-show="errors.has('DescriptionEn')"
                class="badge badge-danger"
              >{{ errors.first('DescriptionEn') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.descriptionSpanish') }}</label>
            <div class="col-12 col-md-3">
              <textarea
                v-model="resort.description.es"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('DescriptionEs') }]"
                type="text"
              />
              <v-popover
                v-if="resort.description.es"
                offset="16"
              >
                <font-awesome-icon
                  class="ml-2 tooltip-target"
                  icon="external-link-square-alt"
                />
                <template slot="popover">
                  <span
                    v-html="resort.description.es"
                  />
                </template>
              </v-popover>
              <span
                v-show="errors.has('DescriptionEs')"
                class="badge badge-danger"
              >{{ errors.first('DescriptionEs') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.descriptionItalian') }}</label>
            <div class="col-12 col-md-3">
              <textarea
                v-model="resort.description.it"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('DescriptionIt') }]"
                type="text"
              />
              <v-popover
                v-if="resort.description.it"
                offset="16"
              >
                <font-awesome-icon
                  class="ml-2 tooltip-target"
                  icon="external-link-square-alt"
                />
                <template slot="popover">
                  <span
                    v-html="resort.description.it"
                  />
                </template>
              </v-popover>
              <span
                v-show="errors.has('DescriptionIt')"
                class="badge badge-danger"
              >{{ errors.first('DescriptionIt') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.descriptionFrench') }}</label>
            <div class="col-12 col-md-3">
              <textarea
                v-model="resort.description.fr"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('DescriptionFr') }]"
                type="text"
              />
              <v-popover
                v-if="resort.description.fr"
                offset="16"
              >
                <font-awesome-icon
                  class="ml-2 tooltip-target"
                  icon="external-link-square-alt"
                />
                <template slot="popover">
                  <span
                    v-html="resort.description.fr"
                  />
                </template>
              </v-popover>
              <span
                v-show="errors.has('DescriptionFr')"
                class="badge badge-danger"
              >{{ errors.first('DescriptionFr') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.homepage') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.homepage"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Homepage') }]"
                type="text"
              >
              <span
                v-show="errors.has('Homepage')"
                class="badge badge-danger"
              >{{ errors.first('Homepage') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <!-- <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">Logo</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.logo"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('Logo') }]"
                type="number"
              >
              <span
                v-show="errors.has('Logo')"
                class="badge badge-danger"
              >{{ errors.first('Logo') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div> -->
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.numberImages') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.numberImages"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('NumberImages') }]"
                type="text"
              >
              <span
                v-show="errors.has('NumberImages')"
                class="badge badge-danger"
              >{{ errors.first('NumberImages') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.weatherUrl') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.weatherUrl"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('WeatherUrl') }]"
                type="text"
              >
              <span
                v-show="errors.has('WeatherUrl')"
                class="badge badge-danger"
              >{{ errors.first('WeatherUrl') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('phone') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.phoneNumber"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('PhoneNumber') }]"
                type="text"
              >
              <span
                v-show="errors.has('PhoneNumber')"
                class="badge badge-danger"
              >{{ errors.first('PhoneNumber') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('email') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.emailAddress"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('EmailAddress') }]"
                type="text"
              >
              <span
                v-show="errors.has('EmailAddress')"
                class="badge badge-danger"
              >{{ errors.first('EmailAddress') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">No Skiline Markup</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.noSkilineMarkup"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('NoSkilineMarkup') }]"
                type="number"
              >
              <span
                v-show="errors.has('NoSkilineMarkup')"
                class="badge badge-danger"
              >{{ errors.first('NoSkilineMarkup') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">External Mapping Prio</label>
            <div class="col-12 col-md-3">
              <select
                v-model="resort.externalIdMappingPrio"
                :class="['form-control m-nav__link cursor-pointer', { 'is-invalid': this.$validator.errors.has('ExternalIdMappingPrio') }]"
              >
                <option
                  v-for="(ext, i) in externalIdMappingPrios"
                  :key="`extype${i}`"
                  :value="ext"
                >
                  {{ ext }}
                </option>
              </select>
              <span
                v-show="errors.has('ExternalIdMappingPrio')"
                class="badge badge-danger"
              >{{ errors.first('ExternalIdMappingPrio') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">Contract Service Admin Tool</label>
            <div class="col-12 col-md-3">
              <a
                href="http://contract-service-ui.production.anexia.skiline.cc/#/terms"
                target="_blank"
              >http://contract-service-ui.production.anexia.skiline.cc/#/terms</a>
            </div>
            <div class="col-12 col-md-7" />
          </div>
        </div>
        <LoadingPlaceholder v-if="infoLoading" />
        <template v-else>
          <h4>{{ $t('resortForm.info') }}</h4>
          <div class="ml-4 mt-4">
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.externalResortId') }}</label>
              <div class="col-12 col-md-3">
                <Multiselect
                  v-if="!createNewResort"
                  id="infos"
                  v-model="skiResortInfo"            
                  :options="skiResortInfos"
                  placeholder="Select a Ski Resort Info"
                  :custom-label="infoLabel"
                  :close-on-select="true"
                  :class="[{ 'is-invalid': this.$validator.errors.has('ExternalResortId') }]"
                />
                <input
                  v-else
                  v-model="infoExternalId"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('ExternalResortId') }]"
                  type="number"
                >
                <span
                  v-if="existsExternalId"
                  class="font-weight-bold"
                  style="color: red;"
                >{{ $t('resortForm.existsExternalId') }}</span>
                <span
                  v-if="hasInfoMoreResorts"
                  class="font-weight-bold"
                  style="color: red;"
                >{{ $t('resortForm.infoWarning') }}</span>
                <span
                  v-show="errors.has('ExternalResortId')"
                  class="badge badge-danger"
                >{{ errors.first('ExternalResortId') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.createNewInfo') }}</label>
              <div class="col-12 col-md-1">
                <input
                  v-model="createNewResort"
                  class="form-control m-input mt-2"
                  type="checkbox"
                >
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.notesGerman') }}</label>
              <div class="col-12 col-md-3">
                <textarea
                  v-model="skiResortInfo.notes.de"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('NotesDe') }]"
                  type="text"
                />
                <v-popover
                  v-if="skiResortInfo.notes.de"
                  offset="16"
                >
                  <font-awesome-icon
                    class="ml-2 tooltip-target"
                    icon="external-link-square-alt"
                  />
                  <template slot="popover">
                    <span
                      v-html="skiResortInfo.notes.de"
                    />
                  </template>
                </v-popover>
                <span
                  v-show="errors.has('NotesDe')"
                  class="badge badge-danger"
                >{{ errors.first('NotesDe') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.notesEnglish') }}</label>
              <div class="col-12 col-md-3">
                <textarea
                  v-model="skiResortInfo.notes.en"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('NotesEn') }]"
                  type="text"
                />
                <v-popover
                  v-if="skiResortInfo.notes.en"
                  offset="16"
                >
                  <font-awesome-icon
                    class="ml-2 tooltip-target"
                    icon="external-link-square-alt"
                  />
                  <template slot="popover">
                    <span
                      v-html="skiResortInfo.notes.en"
                    />
                  </template>
                </v-popover>
                <span
                  v-show="errors.has('NotesEn')"
                  class="badge badge-danger"
                >{{ errors.first('NotesEn') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.notesSpanish') }}</label>
              <div class="col-12 col-md-3">
                <textarea
                  v-model="skiResortInfo.notes.es"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('NotesEs') }]"
                  type="text"
                />
                <v-popover
                  v-if="skiResortInfo.notes.es"
                  offset="16"
                >
                  <font-awesome-icon
                    class="ml-2 tooltip-target"
                    icon="external-link-square-alt"
                  />
                  <template slot="popover">
                    <span
                      v-html="skiResortInfo.notes.es"
                    />
                  </template>
                </v-popover>
                <span
                  v-show="errors.has('NotesEs')"
                  class="badge badge-danger"
                >{{ errors.first('NotesEs') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.notesItalian') }}</label>
              <div class="col-12 col-md-3">
                <textarea
                  v-model="skiResortInfo.notes.it"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('NotesIt') }]"
                  type="text"
                />
                <v-popover
                  v-if="skiResortInfo.notes.it"
                  offset="16"
                >
                  <font-awesome-icon
                    class="ml-2 tooltip-target"
                    icon="external-link-square-alt"
                  />
                  <template slot="popover">
                    <span
                      v-html="skiResortInfo.notes.it"
                    />
                  </template>
                </v-popover>
                <span
                  v-show="errors.has('NotesIt')"
                  class="badge badge-danger"
                >{{ errors.first('NotesIt') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.notesFrench') }}</label>
              <div class="col-12 col-md-3">
                <textarea
                  v-model="skiResortInfo.notes.fr"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('NotesFr') }]"
                  type="text"
                />
                <v-popover
                  v-if="skiResortInfo.notes.fr"
                  offset="16"
                >
                  <font-awesome-icon
                    class="ml-2 tooltip-target"
                    icon="external-link-square-alt"
                  />
                  <template slot="popover">
                    <span
                      v-html="skiResortInfo.notes.fr"
                    />
                  </template>
                </v-popover>
                <span
                  v-show="errors.has('NotesFr')"
                  class="badge badge-danger"
                >{{ errors.first('NotesFr') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.snowphone') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.snowphone"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Snowphone') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('Snowphone')"
                  class="badge badge-danger"
                >{{ errors.first('Snowphone') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ 'Info-Web' }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.website"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Website') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('Website')"
                  class="badge badge-danger"
                >{{ errors.first('Website') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.dayTicketAdult') + ' (' + skiResortInfo.skipassCurrency + ')' }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.skipassOnedayAdultsMainSeason"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SkipassOnedayAdultsMainSeason') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('SkipassOnedayAdultsMainSeason')"
                  class="badge badge-danger"
                >{{ errors.first('SkipassOnedayAdultsMainSeason') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.dayTicketYouth') + ' (' + skiResortInfo.skipassCurrency + ')' }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.skipassOnedayYouthMainSeason"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SkipassOnedayYouthMainSeason') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('SkipassOnedayYouthMainSeason')"
                  class="badge badge-danger"
                >{{ errors.first('SkipassOnedayYouthMainSeason') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.dayTicketChild') + ' (' + skiResortInfo.skipassCurrency + ')' }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.skipassOnedayChildrenMainSeason"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SkipassOnedayChildrenMainSeason') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('SkipassOnedayChildrenMainSeason')"
                  class="badge badge-danger"
                >{{ errors.first('SkipassOnedayChildrenMainSeason') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.slopesEasy') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.slopesEasy"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SlopesEasy') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('SlopesEasy')"
                  class="badge badge-danger"
                >{{ errors.first('SlopesEasy') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.slopesIntermediate') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.slopesIntermediate"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SlopesIntermediate') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('SlopesIntermediate')"
                  class="badge badge-danger"
                >{{ errors.first('SlopesIntermediate') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.slopesDifficult') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.slopesDifficult"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SlopesDifficult') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('SlopesDifficult')"
                  class="badge badge-danger"
                >{{ errors.first('SlopesDifficult') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.operatingTimeFrom') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.operatingTimeFrom"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('OperatingTimeFrom') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('OperatingTimeFrom')"
                  class="badge badge-danger"
                >{{ errors.first('OperatingTimeFrom') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.operatingTimeUntil') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.operatingTimeUntil"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('OperatingTimeUntil') }]"
                  type="text"
                >
                <span
                  v-show="errors.has('OperatingTimeUntil')"
                  class="badge badge-danger"
                >{{ errors.first('OperatingTimeUntil') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.seasonStart') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.seasonStart"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SeasonStart') }]"
                  type="date"
                >
                <span
                  v-show="errors.has('SeasonStart')"
                  class="badge badge-danger"
                >{{ errors.first('SeasonStart') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
            <div
              class="form-group row mb-4"
            >
              <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.seasonEnd') }}</label>
              <div class="col-12 col-md-3">
                <input
                  v-model="skiResortInfo.seasonEnd"
                  :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('SeasonEnd') }]"
                  type="date"
                >
                <span
                  v-show="errors.has('SeasonEnd')"
                  class="badge badge-danger"
                >{{ errors.first('SeasonEnd') }}</span>
              </div>
              <div class="col-12 col-md-7" />
            </div>
          </div>
        </template>
        <h4>{{ $t('resortForm.locationData') }}</h4>
        <div class="ml-4 mt-4">
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('installationList.country') }}</label>
            <div class="col-12 col-md-3">
              <Multiselect
                id="resorts"
                v-model="resort.address.country"            
                :options="resortCountries"
                placeholder="Select Country"
                label="nameDe"
                :close-on-select="true"
                :class="[{ 'is-invalid': this.$validator.errors.has('CountryId') }]"
              />
              <span
                v-show="errors.has('CountryId')"
                class="badge badge-danger"
              >{{ errors.first('CountryId') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.province') }}</label>
            <div class="col-12 col-md-3">
              <Multiselect
                id="resorts"
                v-model="resort.province"            
                :options="filteredResortProvinces"
                placeholder="Select Province"
                label="localName"
                :close-on-select="true"
                :class="[{ 'is-invalid': this.$validator.errors.has('ProvinceId') }]"
              />
              <span
                v-show="errors.has('ProvinceId')"
                class="badge badge-danger"
              >{{ errors.first('ProvinceId') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('zipCode') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.address.zipCode"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Zipcode') }]"
                type="text"
              >
              <span
                v-show="errors.has('Zipcode')"
                class="badge badge-danger"
              >{{ errors.first('Zipcode') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('city') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.address.city"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('City') }]"
                type="text"
              >
              <span
                v-show="errors.has('City')"
                class="badge badge-danger"
              >{{ errors.first('City') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('address') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.address.street"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Street') }]"
                type="text"
              >
              <span
                v-show="errors.has('Street')"
                class="badge badge-danger"
              >{{ errors.first('Street') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.streetNumber') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.address.streetNumber"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('StreetNumber') }]"
                type="text"
              >
              <span
                v-show="errors.has('StreetNumber')"
                class="badge badge-danger"
              >{{ errors.first('StreetNumber') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <i>Location affects the serviceProvider in Contentful, if found</i>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('latitude') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.latitude"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Latitude') }]"
                type="text"
              >
              <span
                v-show="errors.has('Latitude')"
                class="badge badge-danger"
              >{{ errors.first('Latitude') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('longitude') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.longitude"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Longitude') }]"
                type="text"
              >
              <span
                v-show="errors.has('Longitude')"
                class="badge badge-danger"
              >{{ errors.first('Longitude') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.timezoneId') }}</label>
            <div class="col-12 col-md-3">
              <Multiselect
                id="timezoneId"
                v-model="resort.timeZoneId"            
                :options="timezones"
                placeholder="Select Timezone"
                :close-on-select="true"
                :class="[{ 'is-invalid': this.$validator.errors.has('TimeZoneId') }]"
              />
              <span
                v-show="errors.has('TimeZoneId')"
                class="badge badge-danger"
              >{{ errors.first('TimeZoneId') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.inputTimezoneId') }}</label>
            <div class="col-12 col-md-3">
              <input
                v-model="resort.inputTimeZoneId"
                :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('InputTimeZoneId') }]"
                type="text"
              >
              <span
                v-show="errors.has('InputTimeZoneId')"
                class="badge badge-danger"
              >{{ errors.first('InputTimeZoneId') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
        </div>
        <h4>{{ $t('resortForm.resortTicketInformations') }}</h4>
        <div class="ml-4 mt-4">
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.connectionType') }}</label>
            <div class="col-12 col-md-3">
              <select
                v-model="resort.connectorType"
                :class="['form-control m-nav__link cursor-pointer', { 'is-invalid': this.$validator.errors.has('ConnectorType') }]"
              >
                <option
                  v-for="(connectorType, i) in connectorTypes"
                  :key="`conType${i}`"
                  :value="connectorType"
                >
                  {{ connectorType }}
                </option>
              </select>
              <span
                v-show="errors.has('ConnectorType')"
                class="badge badge-danger"
              >{{ errors.first('ConnectorType') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.skipassTypes') }}</label>
            <div class="col-12 col-md-3">
              <Multiselect
                v-model="resortTicketTypes"
                tag-placeholder="Not found"
                placeholder="Type"
                :options="ticketTypes"
                :multiple="true"
                :taggable="true"
                select-label=""
                :hide-selected="true"
                :searchable="false"
                :close-on-select="true"
                :class="[{ 'is-invalid': this.$validator.errors.has('TicketNumberTypes') }]"
              />
              <span
                v-show="errors.has('TicketNumberTypes')"
                class="badge badge-danger"
              >{{ errors.first('TicketNumberTypes') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
        </div>
        <h4>{{ $t('resortForm.installationsAndServices') }}</h4>
        <div class="ml-4 mt-4">
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.hasSkilineChart') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.hasSkilineChart"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('HasSkilineChart') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('HasSkilineChart')"
                class="badge badge-danger"
              >{{ errors.first('HasSkilineChart') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.addressDownload') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.addressDownload"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('AddressDownload') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('AddressDownload')"
                class="badge badge-danger"
              >{{ errors.first('AddressDownload') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.hasLiftsWithoutAccessControl') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.hasLiftsWithoutAccessControl"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('HasLiftsWithoutAccessControl') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('HasLiftsWithoutAccessControl')"
                class="badge badge-danger"
              >{{ errors.first('HasLiftsWithoutAccessControl') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.hasExitReaders') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.hasExitReaders"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('HasExitReaders') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('HasExitReaders')"
                class="badge badge-danger"
              >{{ errors.first('HasExitReaders') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.hasPhotoPoints') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.hasPhotoPoints"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('HasPhotoPoints') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('HasPhotoPoints')"
                class="badge badge-danger"
              >{{ errors.first('HasPhotoPoints') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.hasVideo') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.hasVideo"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('HasVideo') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('HasVideo')"
                class="badge badge-danger"
              >{{ errors.first('HasVideo') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.hasWebcams') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.hasWebcams"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('HasWebcams') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('HasWebcams')"
                class="badge badge-danger"
              >{{ errors.first('HasWebcams') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.hasSkilineCheckpoints') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.hasSkilineCheckpoints"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('HasSkilineCheckpoints') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('HasSkilineCheckpoints')"
                class="badge badge-danger"
              >{{ errors.first('HasSkilineCheckpoints') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.coverAvailable') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.coverAvailable"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('CoverAvailable') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('CoverAvailable')"
                class="badge badge-danger"
              >{{ errors.first('CoverAvailable') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
        </div>
        <h4>{{ $t('resortForm.resortVisibility') }}</h4>
        <div class="ml-4 mt-4">
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.active') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.active"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('Active') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('Active')"
                class="badge badge-danger"
              >{{ errors.first('Active') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.visible') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.visible"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('Visible') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('Visible')"
                class="badge badge-danger"
              >{{ errors.first('Visible') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.visibleInSkiResorts') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.visibleInSkiResorts"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('VisibleInSkiResorts') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('VisibleInSkiResorts')"
                class="badge badge-danger"
              >{{ errors.first('VisibleInSkiResorts') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.displayRanking') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.displayRanking"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('DisplayRanking') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('DisplayRanking')"
                class="badge badge-danger"
              >{{ errors.first('DisplayRanking') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
          <div
            class="form-group row mb-4"
          >
            <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.visibleInTree') }}</label>
            <div class="col-12 col-md-1">
              <input
                v-model="resort.visibleInTree"
                :class="['form-control m-input', { 'is-invalid': this.$validator.errors.has('VisibleInTree') }]"
                type="checkbox"
              >
              <span
                v-show="errors.has('VisibleInTree')"
                class="badge badge-danger"
              >{{ errors.first('VisibleInTree') }}</span>
            </div>
            <div class="col-12 col-md-7" />
          </div>
        </div>
        <h4>Resort Panorama</h4>
        
        <div>
          <div class="ml-4 mt-4">
            <template>
              <div
                class="form-group row mb-4"
              >
                <label class="col-12 col-md-2 mt-2">{{ $t('resortForm.resortPanoramaId') }}</label>
                <div class="col-12 col-md-3">
                  <Multiselect
                    id="resorts"
                    v-model="resort.resortPanorama"            
                    :options="resortPanoramas"
                    placeholder="Select Resort Panorama"
                    label="id"
                    :close-on-select="true"
                    class=" col-4"
                  />
                </div>
                <div class="col-12 col-md-7" />
              </div>
            </template>
          </div>
        </div>

        <button
          v-if="!authenticationHasRole('zerolevel')"
          class="btn btn-sm btn-primary mt-4 float-right"
          @click="updateResort"
        >
          <font-awesome-icon
            class="mr-2"
            icon="upload"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
        
        <DatabaseAuditLogList
          ref="databaseAuditLogList"
          :resort-crud-id="resort.id"
          @reload="getResort"
        /> 
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { VPopover } from 'v-tooltip'

export default {
  name: "ResortDetails",
  components: {
    Multiselect: () => import('vue-multiselect'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue'),
    VPopover
  },
  mixins: [
    errorMixin,
    authenticationMixin,
    dateTimeMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      resort: null,
      resortPanoramas: [],
      resortProvinces: [],
      resortCountries: [],
      loading: true,
      resortTicketTypes: null,
      skiResortInfos: null,
      infoLoading: false,
      skiResortInfo: null,
      createNewResort: false,
      infoExternalId: null,
      existsExternalId: false
    }
  },
  computed: {
    hasInfoMoreResorts () {
      return this.skiResortInfo != null && this.skiResortInfo.names != null && this.skiResortInfo.names.length > 1
    },
    timezones () {
      return this.dateTime_IANA_Timezones();
    },
    connectorTypes () {
      return ["AXESS_3TAELER", "CAR_MOVIE", "CONVERTER", "GENERIC", "SERIAL_NUMBER", "SKIDATA", "SKIDATA_AXESS_3TAELER" ];
    },
    externalIdMappingPrios () {
      return [1, 2, 3];
    },
    ticketTypes () {
      return ["SKIDATA", "OCC", "CHIP_NUMBER", "POS_SERIAL", "WTP", "PROJECT_POS_SERIAL", "BARCODE" ];
    },
    filteredResortProvinces () {
      if (!this.resort.address.country) {
        return this.resortProvinces;
      }
      return this.resortProvinces.filter(o => o.countryCode == this.resort.address.country.shortcut);
    }
  },
  watch: {
    'infoExternalId': function (val) {
      this.existsExternalId = this.skiResortInfos.find(x => x.externalResortId == val);
    },
    'resort.timeZoneId': function (val) {
      this.resort.inputTimeZoneId = this.dateTime_Convert_IANA_Timezone(val);
    },
    'resort.address.country': function (val, old) {
      if (val && old && val.id !== old.id) {
        this.resort.province = {}
        this.resort.provinceId = null
      }
    },
    createNewResort (val) {
      if (val) {
        this.skiResortInfo = {
          notes: {},
          names: []
        };
        this.infoExternalId = null;
        this.existsExternalId = false;
      }
      else {
        if(this.skiResortInfos && this.skiResortInfos.length > 0) {
          this.skiResortInfo = this.skiResortInfos.find(x => x.id == this.resort.infoId);
          this.infoExternalId = null;
          this.existsExternalId = false;
        }
      }
    },
    skiResortInfo () {
      if(this.skiResortInfo != null) {
        this.skiResortInfo.seasonStart = this.dateTime_iso(this.skiResortInfo.seasonStart);
        this.skiResortInfo.seasonEnd = this.dateTime_iso(this.skiResortInfo.seasonEnd);
      }
    }
  },
  async created () {
    await this.getResort();
    this.getResortPanoramas();
    this.getResortProvinces();
    this.getResortCountries();
  },
  methods: {
    infoLabel ({ externalResortId, names }) {
      if(names.length > 2) {
        return `${externalResortId} - ${names.slice(0, 2).join(", ")}, ...`
      }
      return `${externalResortId} - ${names.join(", ")}`
    },
    async getResort () {
      this.loading = true;
      await this.axios.get(`/Resort/GetResortDetails?id=${ this.resortId }`)
        .then((response) => {
          this.resort = response.data;
          if (this.resort.ticketNumberTypes)
          {
            this.resortTicketTypes = this.resort.ticketNumberTypes.split(", ");
          }
          
          this.getSkiResortInfos();
        })
        .finally(() => {
           this.loading = false;
        });
    },
    getResortPanoramas () {
      this.loading = true;
      this.axios.get(`/Resort/GetAllResortPanoramas`)
        .then((response) => {
          this.resortPanoramas = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateResort () {
      if (this.resortTicketTypes)
      {
        this.resort.ticketNumberTypes = this.resortTicketTypes.join(", ");
      }
      if (this.resort.resortPanorama)
      {
        this.resort.resortPanoramaId = this.resort.resortPanorama.id;
      }
      if (this.resort.province)
      {
         this.resort.provinceId = this.resort.province.id;
      }
      if (this.resort.address.country)
      {
          this.resort.address.countryId = this.resort.address.country.id;
      }
      if(this.infoExternalId != null) {
        this.skiResortInfo.externalResortId = this.infoExternalId;
      }

      this.resort.skiResortInfo = this.skiResortInfo;
      this.axios.put(`/Resort/UpdateResort`, this.resort)
        .then(() => {
          this.error_clear();
          this.$snotify.success("Successfully updated Resort!");
          this.getResort();
        })
        .finally(() => {
          this.reloadResortCache();
          this.createNewResort = false;
        })
        .catch((error) => {
          this.$snotify.error("Error updating Resort!");
          this.error_clear();
          this.error_validate(error);
        });
    },
    getResortProvinces () {
      this.loading = true;
      this.axios.get(`/Resort/GetAllResortProvinces`)
        .then((response) => {
          this.resortProvinces= response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getResortCountries () {
      this.loading = true;
      this.axios.get(`/Resort/GetAllResortCountries`)
        .then((response) => {
          this.resortCountries= response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getSkiResortInfos () {
      this.infoLoading = true;
      this.axios.get(`/Resort/GetSkiResortInfos`)
        .then((response) => {
          this.skiResortInfos = response.data;
          if(this.skiResortInfos && this.skiResortInfos.length > 0) {
            this.skiResortInfo = this.skiResortInfos.find(x => x.id == this.resort.infoId);
            if(this.skiResortInfo != null) {
              this.skiResortInfo.seasonStart = this.dateTime_iso(this.skiResortInfo.seasonStart);
              this.skiResortInfo.seasonEnd = this.dateTime_iso(this.skiResortInfo.seasonEnd);
            }
          }
        })
        .finally(() => {
          this.infoLoading = false;
        });
    },
    async reloadResortCache () {
      this.axios.post(`/Resort/ReloadResortCache`)
      .then(() => {
      })
      .catch(() => {
        this.$snotify.success('Reload Resort Cache not successfull - changes are not yet applied to the system!');
      });
    }
  }
}
</script>

<style>
.tooltip.popover .popover-inner {
  background: white;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(white, .1);
  max-width: 1000px;
}

.popover-arrow {
  border-color: white;
}
.tooltip-arrow {
  z-index: 1;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
